import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'myaccount',
  templateUrl: './myaccount.component.html',
  // styleUrls: ['./myaccount.component.scss']
  encapsulation: ViewEncapsulation.None
})
export class MyaccountComponent implements OnInit {

  form = new UntypedFormGroup({
    name: new UntypedFormControl('', [Validators.required]),
    price: new UntypedFormControl('', [Validators.required, Validators.pattern(/^[0-9]*$/)]),
    seatCount: new UntypedFormControl('', [
      Validators.required,
      Validators.pattern(/^[0-9]*$/),
      Validators.min(0)
    ]),
    image: new UntypedFormControl('', [Validators.required]),
  });

  constructor(private snack: MatSnackBar) { }

  ngOnInit() {
  }

  onSubmit(): void {
    console.log(this.form.value);

  }

}
