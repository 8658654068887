import { Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from '@env';

@Injectable()
export class IconsService {
  constructor(private icon: MatIconRegistry, private domSanitizer: DomSanitizer) {
    this.icon.addSvgIcon(
      'down',
      this.domSanitizer.bypassSecurityTrustResourceUrl(`${environment.path.img}/svg/down.svg`)
    );

    this.icon.addSvgIcon(
      'search',
      this.domSanitizer.bypassSecurityTrustResourceUrl(`${environment.path.img}/svg/search.svg`)
    );

    this.icon.addSvgIcon(
      'bear',
      this.domSanitizer.bypassSecurityTrustResourceUrl(`${environment.path.img}/svg/bear.svg`)
    );

    this.icon.addSvgIcon(
      'bear-popin',
      this.domSanitizer.bypassSecurityTrustResourceUrl(`${environment.path.img}/svg/bear-popin.svg`)
    );

    this.icon.addSvgIcon(
      'alert-mail',
      this.domSanitizer.bypassSecurityTrustResourceUrl(`${environment.path.img}/svg/alert-mail.svg`)
    );

    this.icon.addSvgIcon(
      'facebook',
      this.domSanitizer.bypassSecurityTrustResourceUrl(`${environment.path.img}/svg/facebook.svg`)
    );

    this.icon.addSvgIcon(
      'twitter',
      this.domSanitizer.bypassSecurityTrustResourceUrl(`${environment.path.img}/svg/twitter.svg`)
    );

    this.icon.addSvgIcon(
      'youtube',
      this.domSanitizer.bypassSecurityTrustResourceUrl(`${environment.path.img}/svg/youtube.svg`)
    );

    this.icon.addSvgIcon(
      'linkedin',
      this.domSanitizer.bypassSecurityTrustResourceUrl(`${environment.path.img}/svg/linkedin.svg`)
    );

    this.icon.addSvgIcon(
      'instagram',
      this.domSanitizer.bypassSecurityTrustResourceUrl(`${environment.path.img}/svg/instagram.svg`)
    );

    this.icon.addSvgIcon(
      'check',
      this.domSanitizer.bypassSecurityTrustResourceUrl(`${environment.path.img}/svg/check.svg`)
    );

    this.icon.addSvgIcon(
      'bear-handup',
      this.domSanitizer.bypassSecurityTrustResourceUrl(`${environment.path.img}/svg/bear-handup.svg`)
    );

    this.icon.addSvgIcon(
      'chat-bubbles',
      this.domSanitizer.bypassSecurityTrustResourceUrl(`${environment.path.img}/svg/chat-bubbles.svg`)
    );

    this.icon.addSvgIcon(
      'cart',
      this.domSanitizer.bypassSecurityTrustResourceUrl(`${environment.path.img}/svg/cart.svg`)
    );

    this.icon.addSvgIcon(
      'trash',
      this.domSanitizer.bypassSecurityTrustResourceUrl(`${environment.path.img}/svg/trash.svg`)
    );

    this.icon.addSvgIcon(
      'home',
      this.domSanitizer.bypassSecurityTrustResourceUrl(`${environment.path.img}/svg/home.svg`)
    );

    this.icon.addSvgIcon(
      'download',
      this.domSanitizer.bypassSecurityTrustResourceUrl(`${environment.path.img}/svg/download.svg`),
    );

    this.icon.addSvgIcon(
      'close',
      this.domSanitizer.bypassSecurityTrustResourceUrl(`${environment.path.img}/svg/close-popin.svg`),
    );

    this.icon.addSvgIcon(
      'scrolltop',
      this.domSanitizer.bypassSecurityTrustResourceUrl(`${environment.path.img}/svg/scrolltop.svg`),
    );

    this.icon.addSvgIcon(
      'highlight_off',
      this.domSanitizer.bypassSecurityTrustResourceUrl(`${environment.path.img}/svg/highlight_off.svg`),
    );

    this.icon.addSvgIcon(
      'speaker',
      this.domSanitizer.bypassSecurityTrustResourceUrl(`${environment.path.img}/svg/speaker.svg`),
    );

    this.icon.addSvgIcon(
      'corporate_fare',
      this.domSanitizer.bypassSecurityTrustResourceUrl(`${environment.path.img}/svg/corporate_fare.svg`),
    );

    this.icon.addSvgIcon(
      'thumbs_up_down_outline',
      this.domSanitizer.bypassSecurityTrustResourceUrl(`${environment.path.img}/svg/thumbs_up_down_outline.svg`),
    );

    this.icon.addSvgIcon(
      'push_pin',
      this.domSanitizer.bypassSecurityTrustResourceUrl(`${environment.path.img}/svg/push_pin.svg`),
    );

    this.icon.addSvgIcon(
      'open_in_full',
      this.domSanitizer.bypassSecurityTrustResourceUrl(`${environment.path.img}/svg/open_in_full.svg`),
    );

    this.icon.addSvgIcon(
      'id_card',
      this.domSanitizer.bypassSecurityTrustResourceUrl(`${environment.path.img}/svg/id_card.svg`),
    );


    this.icon.addSvgIcon(
      'search-header',
      this.domSanitizer.bypassSecurityTrustResourceUrl(`${environment.path.img}/svg/search-header.svg`),
    );
  }
}