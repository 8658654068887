import { Component, ViewEncapsulation, Inject } from '@angular/core';
import { UntypedFormGroup, Validators, UntypedFormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'popin-login',
  templateUrl: './popin-login.component.html',
  styleUrls: ['./popin-login.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PopinLoginComponent {
  email: string;
  submitted: boolean = false;


  formEmailSubscription = new UntypedFormGroup({
    email: new UntypedFormControl('', [Validators.required])
  });

  constructor(
    public dialogRef: MatDialogRef<PopinLoginComponent>,
    @Inject(MAT_DIALOG_DATA) public data) {
    this.email = data.email;
  }

  onSubmit(): void {
    this.dialogRef.close({
      submitted: this.formEmailSubscription.controls['email'].value! ? true : false,
      email: this.formEmailSubscription.controls['email'].value
    });

  }
}
