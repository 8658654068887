import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import { BreakpointService } from 'src/app/core/services/breakpoints.service';
import { MatDialogConfig } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BreakpointState } from '@angular/cdk/layout';
import { take } from 'rxjs/operators';
import { environment } from '@env';
import { Subscription } from 'rxjs';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'jobtile',
  templateUrl: './jobtile.component.html',
  styleUrls: ['./jobtile.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class JobtileComponent implements OnInit {
  public isDesktop: boolean;

  public imgPath: string = environment.path.img;

  breakpointsSub: Subscription;

  /* items = [
    { id: 0, isAdded: true },
    { id: 1, isAdded: false },
    { id: 2, isAdded: false },
    { id: 3, isAdded: false }
  ]; */

  @Input() public item: any;
  @Input() public isJobview: boolean; // For using in slide mode
  @Input() public logoUrl: string;

  date: any;

  constructor(public breakpoints: BreakpointService, private snack: MatSnackBar, private datePipe: DatePipe) {
  }

  popinConf = new MatDialogConfig();

  ngOnInit() {
    this.breakpointsSub = this.breakpoints.isDesktop.subscribe((state: BreakpointState) => {
      state.matches ? this.isDesktop = true : this.isDesktop = false;
    });

    this.transformDate();
  }

  ngOnDestroy(): void {
    this.breakpointsSub.unsubscribe();

  }

  transformDate() {
    const todayDate = this.datePipe.transform(Date.now(), 'dd/MM/yy');
    this.date = this.datePipe.transform(this.item.PUBDATE, 'dd/MM/yy');

    if (todayDate === this.date) {
      this.date = 'Aujourd\'hui';
    } else {
      this.date = this.datePipe.transform(this.item.PUBDATE, 'mediumDate', undefined, 'fr');
    }

  }

  addToFavorite(id) {
    /* this.itemsList.forEach((item) => {
      if (item.id == id) {
        item.isAdded = !item.isAdded;

        item.isAdded ? this.snack.open('Annonce ajoutée aux favoris', 'OK', { duration: 2000 }) : this.snack.open('Annonce supprimée des favoris', 'OK', { duration: 2000 });
      }
    }) */

    this.item.isAdded = !this.item.isAdded;
    this.item.isAdded ? this.snack.open('Annonce ajoutée aux favoris', 'OK', { duration: 2000 }) : this.snack.open('Annonce supprimée des favoris', 'OK', { duration: 2000 });
  };
}
