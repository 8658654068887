import { Injectable } from '@angular/core';
import {
  HttpInterceptor, HttpHandler, HttpRequest, HttpHeaders, HttpResponse, HttpErrorResponse, HttpEvent
} from '@angular/common/http';


import { ApiService } from '../services/api.service';
import { environment } from 'src/environments/environment';
import { AuthService } from '../services/auth.service';
import { EMPTY, Observable } from 'rxjs';
import { ErrorsService } from '../services/errors.service';
import { EventEmitter } from 'protractor';
import { REQUEST } from '@nguniversal/express-engine/tokens';
import { map } from 'rxjs/operators';
// import { MsalService } from '@azure/msal-angular';
import { env } from 'process';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  idToken: any;
  jwt;

  constructor(private api: ApiService, /*private auth: MsalService,*/ private errorService: ErrorsService) {

  }

  // getJWT() {
  //   this.auth.instance.getTokenCache()['storage'].browserStorage.getKeys().forEach(el => {
  //     if (el.includes('idtoken')) {
  //       this.idToken = JSON.parse(this.auth.instance.getTokenCache()['storage'].browserStorage.windowStorage.getItem(el));
  //       this.jwt = this.idToken.secret;
  //     }
  //   });
  // }

  apimKey = environment.production ? 'b0dc67b6ea12480d9e9f391d18d6f342' : 'e8e5065217e748a99df5b20305373b25'
  intercept(req: HttpRequest<any>, next: HttpHandler) {
    let headersOptions = new HttpHeaders();

    // API URL
    if (req.url.includes(environment.path.api) || req.url.includes(environment.path.api3)) {
      // headersOptions.append('Access-Control-Allow-Origin', '*');
      // headersOptions.append('Content-Type', 'application/json');
      // headersOptions.append('Access-Control-Request-Headers', 'application/json; charset=utf-8');
      // headersOptions.append('Authorization', `Bearer ${this.key}`);

      // this.jwt = "eyJhbGciOiJSUzI1NiIsImtpZCI6IklRaktSTTVZRFRmWHV4dlNjR1ZBMnZhUFpiWE1zM1oxdld5M1dCcmZtWkEiLCJ0eXAiOiJKV1QifQ.eyJhdWQiOiJjMjFiOGQ3Ni04MDdlLTRhZTYtYjNkMC0yMTM4Y2NjY2MxM2YiLCJpc3MiOiJodHRwczovL2FjY291bnRzLnByZXByb2Quam9ib2xvZ3kuZnIvYmM4NmNiNzAtN2YyMC00NDQxLTlmMzItOThkMjI0NGNmNjg0L3YyLjAvIiwiZXhwIjoxNzIxODExMDIxLCJuYmYiOjE3MjE4MDc0MjEsInN1YiI6IjA0ZWM3MzcyLTg1MTAtNGQzZC04ZTBlLTI5YTU2YzVlOGY1MyIsImVtYWlsIjoicmVjcnV0ZW1lbnRAamFyZGVsLmV1IiwibmFtZSI6InJlY3J1dGVtZW50QGphcmRlbC5ldSIsImdpdmVuX25hbWUiOiJTb25pYSIsImZhbWlseV9uYW1lIjoiVEFIQVJJIiwidGlkIjoiYmM4NmNiNzAtN2YyMC00NDQxLTlmMzItOThkMjI0NGNmNjg0IiwiaXNGb3Jnb3RQYXNzd29yZCI6ZmFsc2UsIm5vbmNlIjoiM2M5MWNkZDItNmU4OC00M2U2LTkzYTItNTZmYTMwYjJlOTdhIiwic2NwIjoiZnVsbCIsImF6cCI6ImUyZjJhZWUzLThiZmItNGM0NC04MzMyLTNhYTEyYzY3MDAwZSIsInZlciI6IjEuMCIsImlhdCI6MTcyMTgwNzQyMX0.V1jlNBBTCsuZShL7NCQpwnas68w5YbkszWzQDnyKVeuySEvNV-8YbzUx7p0uTlX2YHBOQeRWxKULpt6QYy_Ks-MaIBqYfn60zWBjSxOaNpeO7c9UcMqk19GFhiAyFopX-O5tIwmdc6uKIVVFvcVZY2AMAeK_1tdHXKf1jjdguSFM4_yomUMHPOYVHPFvJEVGFwNfkrPavb2HPqZtJyT-9ojPbOGnLnTZmkRB9mQ9mxloh1TDrCeEEzbY9ycQnfAl3EbbOdnUyj-HvBGAmY47TWGPOYHPn7uUrDj6GGQfZ0iQRb-f2hikuDlGJ1rIo2TzyXkjlMvIJ1My6gKnKEIJbA"

      // req = req.clone({
      //   setHeaders: {
      //     'Authorization': `Bearer ${this.jwt}`,
      //   }
      // });

      if (req.url.includes(environment.path.api)) {
        if (environment.production) {
          req = req.clone({
            setHeaders: {
              'siteKey': environment.GlobalVars.brand,
              'Ocp-Apim-Subscription-Key': this.apimKey
            }
          });
        } else {
          req = req.clone({
            setHeaders: {
              'siteKey': environment.GlobalVars.brand,
            }
          });
        }

      }

      /*       if (this.auth.instance.getActiveAccount()) {
              this.getJWT();
      
              this.jwt = "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImtpZCI6IklRaktSTTVZRFRmWHV4dlNjR1ZBMnZhUFpiWE1zM1oxdld5M1dCcmZtWkEifQ.eyJpc3MiOiJodHRwczovL2FjY291bnRzLnByZXByb2Quam9ib2xvZ3kuZnIvYmM4NmNiNzAtN2YyMC00NDQxLTlmMzItOThkMjI0NGNmNjg0L3YyLjAvIiwiZXhwIjoxNjU4NDEwNDgxLCJuYmYiOjE2NTg0MDY4ODEsImF1ZCI6ImMyMWI4ZDc2LTgwN2UtNGFlNi1iM2QwLTIxMzhjY2NjYzEzZiIsInN1YiI6IjA0ZWM3MzcyLTg1MTAtNGQzZC04ZTBlLTI5YTU2YzVlOGY1MyIsIm5hbWUiOiJTb25pYSBUQUhBUkkiLCJnaXZlbl9uYW1lIjoiVEFIQVJJIiwiZmFtaWx5X25hbWUiOiJTb25pYSIsInVzZXJJZCI6IjMzYWM3NDlmLTE1ZjgtZWExMS1hODU1LTAwNTA1NjlhNmRiMiIsImNvbXBhbnkiOiJKQVJERUwgVHJhbnNwb3J0IGV0IExvY2F0aW9uIiwiY29tcGFueUlkIjoiZDY3ZTFmZGEtMjFkMi1kYjExLWJkNDEtMDAxMzcyM2RlOWJjIiwicm9sZXMiOlsiUkVDUlVURVVSLUNPT1JESU5BVEVVUiIsIlJFQ1JVVEVVUiJdLCJzb3VyY2VVcmwiOiJwcmVwcm9kLmpvYnRyYW5zcG9ydC5jb20iLCJuZXdVc2VyIjpmYWxzZSwidGlkIjoiYmM4NmNiNzAtN2YyMC00NDQxLTlmMzItOThkMjI0NGNmNjg0IiwiaXNGb3Jnb3RQYXNzd29yZCI6ZmFsc2UsIm5vbmNlIjoiYTQ0YTFmZDEtMzRiNS00YmI3LThiNTgtZGJlOTAzZjE4ZWY1Iiwic2NwIjoiZnVsbCIsImF6cCI6Ijk3ZDg5NTBiLTliNDktNDg2YS05NWRhLWEyOWIyYTZhMGQzZCIsInZlciI6IjEuMCIsImlhdCI6MTY1ODQwNjg4MX0.WeZ1L106vETKwegRc9Gue9_PKT2osjLkXdKVlFS2m_59Rj-ilNXIedXtaWG6DEl5R-EfH6P8ER6WIF0Xpt0fxxXZISa3Mj_nUQeOhB00qGhFflqG3SVh9i77L7fq2spejBDv1rjhc3dfi090thefdeSXtysHZ3FQzEwZFXG0cEcmDhgASbis1d-ur4KFSLTrjXbpldyNN_4sq0i-ija4Ohj5TshtrKZifQL0TTmmm3JIykCk-HlAGDUQeZAPqofI0ZoH3ejxHY0gTjKnA4lu8-fPmhVa2nG_fncG5_8Gn4WStwoc6giqukuDLSuBwANUVBMKVln-b3jerHI5ZoRc6g"
      
              req = req.clone({
                setHeaders: {
                  'Authorization': `bearer ${this.jwt}`,
                  'Content-Type': 'application/json',
                  'Ocp-Apim-Subscription-Key': this.apimKey
                  // 'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
                  // 'siteKey': environment.GlobalVars.brand
                }
              });
      
              // console.log('Api Jobology intercepted', req.url);
            } */

      return Observable.create(observer => {
        next.handle(req).subscribe(
          event => {
            if (event instanceof HttpResponse) {
              observer.next(event);
            }
          },
          err => {
            // Handle errors only for GraphQL API
            if (req.url.includes(environment.path.api)) {
              this.errorService.handleError(err);
            }

            observer.error(err);

          },
          () => {
            observer.complete();
          }
        )
      });
    }

    // Auth URL
    if (req.url.includes(environment.path.auth)) {
      return Observable.create(observer => {
        next.handle(req).subscribe(
          event => {
            if (event instanceof HttpResponse) {
              observer.next(event);
            }

          },
          err => {
            this.errorService.handleAuthError(err);
            observer.error(err);

          },
          () => {
            observer.complete();
          }
        )
      });
    }


    /*console.log('INTERCEPTOR BODY', REQUEST);
    console.log('INTERCEPTOR 2', req);
    return next.handle(req).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          console.log('event--->>>', event);
        }
        return event;
      }));;*/

    return next.handle(req);
  }
}