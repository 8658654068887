/**
 * This file contains authentication parameters. Contents of this file
 * is roughly the same across other MSAL.js libraries. These parameters
 * are used to initialize Angular and MSAL Angular configurations in
 * in app.module.ts file.
 */

import { BrowserCacheLocation, Configuration } from '@azure/msal-browser';
import { environment } from '@env';

// const isIE = window.navigator.userAgent.indexOf("MSIE ") > -1 || window.navigator.userAgent.indexOf("Trident/") > -1;

/**
 * Enter here the user flows and custom policies for your B2C application,
 * To learn more about user flows, visit https://docs.microsoft.com/en-us/azure/active-directory-b2c/user-flow-overview
 * To learn more about custom policies, visit https://docs.microsoft.com/en-us/azure/active-directory-b2c/custom-policy-overview
 */
export const b2cPolicies = {
  names: {
    signUpSignIn: "B2C_1A_SIGNUP_SIGNIN",
    editProfile: "b2c_1_edit_profile_v2"
  },
  authorities: {
    signUpSignIn: {
      authority: environment.production ?
        `${environment.path.auth}/jobology.onmicrosoft.com/B2C_1A_SIGNUP_SIGNIN` :
        `${environment.path.auth}/jobologypreprod.onmicrosoft.com/B2C_1A_SIGNUP_SIGNIN`,
    }
  },
  authorityDomain: environment.path.auth
};

/**
 * Configuration object to be passed to MSAL instance on creation. 
 * For a full list of MSAL.js configuration parameters, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md 
 */
export const msalConfig: Configuration = {
  auth: {
    clientId: environment.production ? 'ea5611e4-4962-4840-a1df-22d69c43db26' : '97d8950b-9b49-486a-95da-a29b2a6a0d3d', // This is the ONLY mandatory field that you need to supply.
    authority: b2cPolicies.authorities.signUpSignIn.authority, // Defaults to "https://login.microsoftonline.com/common"
    knownAuthorities: [b2cPolicies.authorityDomain], // Mark your B2C tenant's domain as trusted.
    redirectUri: `${environment.path.root}/mon-compte/callback`, // Points to window.location.origin. You must register this URI on Azure portal/App Registration.
    // redirectUri: `http://localhost:4000/mon-compte/callback`, // Points to window.location.origin. You must register this URI on Azure portal/App Registration.
    postLogoutRedirectUri: `${environment.path.root}/mon-compte/se-deconnecter`, // Indicates the page to navigate after logout.
    // postLogoutRedirectUri: `http://localhost:4000/mon-compte/se-deconnecter`, // Indicates the page to navigate after logout.
    navigateToLoginRequestUrl: false, // If "true", will navigate back to the original request location before processing the auth code response.
  },
  cache: {
    cacheLocation: BrowserCacheLocation.LocalStorage, // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
    // storeAuthStateInCookie: isIE, // Set this to "true" if you are having issues on IE11 or Edge
  },
  system: {
    /* loggerOptions: {
      loggerCallback(logLevel: LogLevel, message: string) {
        console.log(message);
      },
      logLevel: LogLevel.Verbose,
      piiLoggingEnabled: false
    } */
  }
}

/**
 * An optional silentRequest object can be used to achieve silent SSO
 * between applications by providing a "login_hint" property.
 */
export const silentRequest = {
  scopes: ["openid"],
  loginHint: "example@domain.net"
};