// Service
import { Injectable, INJECTOR, Inject, PLATFORM_ID, NgZone } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Subject, Subscription } from 'rxjs';
import { BrowserStorage } from 'angular-auth-oidc-client';
import { ApiService } from './api.service';
import { environment } from '@env';
import { MatDialogRef } from '@angular/material/dialog';
import { Location, isPlatformBrowser } from '@angular/common';

@Injectable()
export class RouterNavService {

  private previousUrl: string = undefined;
  private currentUrl: string = undefined;

  routerSub: Subscription = new Subscription;
  isRecruiters: Subject<boolean> = new Subject<boolean>();
  isRecruiters$ = this.isRecruiters.asObservable();

  constructor(private router: Router, private api: ApiService, private location: Location,
    @Inject(PLATFORM_ID) private platformId, private zone: NgZone) {
    this.currentUrl = this.router.url;

    this.routerSub = this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.handleUrl(this.currentUrl, event);
        //this.setPreviousUrlStorage();

        // Handle Site Logs
        if (environment.production) {
          this.zone.runOutsideAngular(() => {
            this.api.siteLog().subscribe(res => {
              //console.log(res);
            });
          })

        }

        // this.router.url.includes('espace-recruteurs') ? this.isRecruiters.next(true) : this.isRecruiters.next(false);
      }
    });
  }

  private setPreviousUrlStorage() {
    if (!this.currentUrl.includes('/callback') && !this.previousUrl.includes('/callback')
      && !this.previousUrl.includes('/silent-renew')) {
      // console.log('previous url routernav', this.previousUrl);

      (<any>sessionStorage).setItem('returnurl', this.previousUrl);
      /*if (this.previousUrl === '/') {
        (<any>sessionStorage).setItem('returnurl', (<any>window).location.origin);
      }*/
    }
  }

  private handleUrl(url: string, event: any) {
    const loginUrl = 'mon-compte/identification';
    const dashboardUrl = 'ats/postes';


    this.previousUrl = url;
    this.currentUrl = event.url;

    if (url === '/' || url.includes(`/${loginUrl}`) || url.includes('/inscription')) {
      this.previousUrl = dashboardUrl;
    } else if (url.includes('/panier')) {
      // Delete params "step=xx"
      this.previousUrl = '/panier';
    }
  }
}