import { Component, ViewChild, OnInit, ViewEncapsulation, Input } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';


@Component({
  selector: 'searchblock',
  templateUrl: './searchblock.component.html',
  styleUrls: ['./searchblock.component.scss'],
  // encapsulation: ViewEncapsulation.None
})

export class SearchblockComponent implements OnInit {
  // @ViewChild('input') input: any;
  @Input() title: string = 'Rechercher';
  @Input() headerSearch: boolean = false;

  formSearchBlock = new UntypedFormGroup({
    job: new UntypedFormControl('', [Validators.required]),
    city: new UntypedFormControl()
  });

  constructor() { }

  ngOnInit() {
    // console.log(this.input.nativeElement.parentElement);
    // this.input.nativeElement.parentNode.className = 'searchblock__wrapper';
  }

  onSubmit(): void {
    console.log(this.formSearchBlock.valid);
  }
}
