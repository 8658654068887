import { Component, ViewEncapsulation, Renderer2, ElementRef, Inject, ViewChild, NgZone, PLATFORM_ID } from '@angular/core';
import { environment } from '../environments/environment'
import { BreakpointService } from './core/services/breakpoints.service';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { Meta, Title } from '@angular/platform-browser';
import { Subscription, Subject, filter, takeUntil } from 'rxjs';
import { NgxFaviconService } from './lib/ngx-favicon/ngx-favicon.service';
import { customFavicons, CustomFavicon } from 'src/favicon.config';
import { CookieService } from 'ngx-cookie-service';
import { ScriptLoaderService } from './core/services/scriptloader.service';
import { MetaService } from './core/services/meta.service';
//import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
//import { AuthMSALService } from './core/services/auth-msal.service';
import { AuthenticationResult, EventMessage, EventType, InteractionStatus } from '@azure/msal-browser';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';


@Component({
  selector: 'app',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AppComponent {
  isMobile: boolean;
  showSecondTheme: boolean;
  env = environment;
  title = this.env.GlobalVars.name;
  envName = this.title[0].toUpperCase() + this.title.substr(1, this.title.length);

  routerSub: Subscription = new Subscription;

  public CustomFavicon: typeof CustomFavicon = CustomFavicon;
  faviconBrand = 'favicon' + this.env.GlobalVars.brand;

  private readonly _destroying$ = new Subject<void>();

  constructor(@Inject(DOCUMENT) private document: Document, private metaServ: MetaService, private metas: Meta, private el: ElementRef,
    private renderer: Renderer2, public breakpoints: BreakpointService,
    private ngxFaviconService: NgxFaviconService<CustomFavicon>, private cookie: CookieService,
    private script: ScriptLoaderService, @Inject(PLATFORM_ID) private platformId: Object,
    private zone: NgZone, /*private msalBroadcastService: MsalBroadcastService,
    private msal: MsalService, private authService: AuthMSALService,*/ private breakpointObserver: BreakpointObserver) {

    this.breakpoints.isMobile
      .subscribe((state) => {
        this.isMobile = state.matches;
      });

    if (isPlatformBrowser(this.platformId)) {
      this.script.initGTM();
    }

    /*this.routerSub = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {

      if (this.router.url.includes('espace-recruteurs')) {
        this.metaTitle.setTitle(this.env.DK.metaTitleR);
        this.meta.updateTag({ name: 'description', content: this.env.DK.metaDescriptionR });
      } else {
        this.metaTitle.setTitle(this.env.DK.metaTitle);
        this.meta.updateTag({ name: 'description', content: this.env.DK.metaDescription });
      }

      }
    });*/

    // Set FAVICON
    this.ngxFaviconService.setFaviconByUrl(`${this.env.path.img}/${customFavicons[this.faviconBrand]}`);

    // Theme Color
    this.metas.updateTag({ name: 'theme-color', content: this.env.GlobalVars.primaryColor });

    // this.setManifest();
  }

  ngOnInit() {
    // this.initAuth();

    // this.zone.runOutsideAngular(() => {
    //   this.script.initGTM();
    // });

    this.setMetas();
    this.renderer.addClass(this.el.nativeElement, this.env.GlobalVars.name);

    // this.authService.initAuth();

    const body = this.el.nativeElement.parentElement;
    this.renderer.addClass(body, 'main-theme');
  }

  ngAfterViewInit() {
    // this.zone.runOutsideAngular(() => {
    //   this.script.initGTM();
    // });

    // this.showCookiesBarre = this.cookie.get('displayCookieConsent') === 'y' ? false : true;
  }

  // initAuth() {
  //   this.msalBroadcastService.inProgress$
  //     .pipe(
  //       filter((status: InteractionStatus) => status === InteractionStatus.None),
  //       takeUntil(this._destroying$)
  //     )
  //     .subscribe(() => {

  //       if (this.msal.instance.getActiveAccount() == null) {
  //         this.authService.login();
  //       }

  //       this.authService.setLoginDisplay();
  //       this.authService.datas = this.msal.instance.getActiveAccount()?.idTokenClaims;
  //       /*   if (this.authService.datas?.name) {
  //           this.authService.datas.shortname = this.headerService.getShortname(this.authService.datas?.family_name, this.authService.datas?.given_name);
  //         } */

  //     });

  //   this.msalBroadcastService.msalSubject$
  //     .pipe(
  //       filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS),
  //       takeUntil(this._destroying$)
  //     )
  //     .subscribe((result: EventMessage) => {
  //       const payload = result.payload as AuthenticationResult;
  //       this.msal.instance.setActiveAccount(payload.account);
  //     });
  // }


  setManifest() {
    if (environment.production) {
      this.document.querySelector('#manifest').setAttribute('href', `/manifests/manifest-${this.env.GlobalVars.brand}.webmanifest`);
    } else {
      this.document.querySelector('#manifest').setAttribute('href', `/manifests/manifest.webmanifest`);
    }

    // Apple touch icon
    this.document.querySelector('#appleIcon').setAttribute('href', `${environment.path.img}/icons/apple-touch-icon.png`);
  }

  setMetas() {
    this.metaServ.setTitleDescription(this.env.DK.metaTitle, this.env.DK.metaDescription);
  }

  @ViewChild('cookiesBarre', { static: true }) cookiesBarre;
  showCookiesBarre: boolean = false;

  removeCookiesBarre(event) {
    event.stopPropagation();
    this.showCookiesBarre = false;
    this.cookie.set('displayCookieConsent', 'y', 90);
  }

  closeSidenav = new Subject<boolean>();
  dismissSidenav() {
    this.closeSidenav.next(true);
  }


  public setDefaultFavicon(): void {
    this.ngxFaviconService.setDefaultFavicon();
  }

  public setCustomFavicon(faviconName: CustomFavicon): void {
    this.ngxFaviconService.setCustomFavicon(faviconName);
  }

  public setFaviconByUrl(faviconUrl: string): void {
    this.ngxFaviconService.setFaviconByUrl(faviconUrl);
  }

  ngOnDestroy() {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }
}
